<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-content">
            <div class="heading-title">
              <h2>Cartões de crédito</h2>
              <p>
                Essas são os seus cartões de crédito 🤑
              </p>
            </div>
          </div>
          <button class="btn btn-primary pull-right ml-5 action-income" type="button" v-modal="{ target: 'create-card-modal' }"><span><i class="mdi mdi-plus-circle-outline"></i></span> Cadastrar uma cartão </button>
        </div>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="owl-nav" style="position: relative; width: 35%; top: 0; margin: 0 auto; margin-top:20px;margin-bottom:23px;">
          <calendar-co :month="monthStart" :year="yearStart" :callBack="changeDate" :navigateTo="navigateDateTo"></calendar-co>
        </div>
      </div>
      <div class="ol-lg-8 col-md-8 col-sm-8">
        <div class="card">
          <div class="card-body relative">
              <div class="transaction-progress" style="width: 70%;">
                <div class="item mt-5">
                  <strong class="pull-right ng-binding">{{cards.total_progress}}%</strong>
                  <p class="text-muted"><i class="mdi mdi-checkbox-blank-circle-outline" :class="{'text-danger': cards.total_progress > 85, 'text-warning': cards.total_progress > 70 && cards.total_progress < 85, 'text-primary': cards.total_progress < 70 }"></i> Limite total usado</p>
                  <div class="progress progress-bar-success-alt">
                    <div role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" class="progress-bar" :style="`width: ${cards.total_progress}%;`" :class="{'progress-bar-danger': cards.total_progress > 85, 'progress-bar-warning': cards.total_progress > 70 && cards.total_progress < 85, 'progress-bar-success': cards.total_progress < 70 }" >
                    </div>
                  </div>
                  <h4 class="reports-expenses text-left font-w-100 ng-binding" style="margin-bottom:25px; display:block;">{{cards.total_spent | currency }} de {{cards.total_limit | currency }}</h4>
                </div>
              </div>
              <div></div>
              <img :src="`/assets/images/credit.png`" style="opacity: 0.2; margin-top: 10px;" class="nothing-image w-15p right-transaction">
          </div>
        </div>
      </div>
      <div class="ol-lg-4 col-md-4 col-sm-4">
        <div class="card">
          <div class="card-body">
              <div class="chart-insights text-left">
                <p>Limite total disponível</p>
                <h3><strong class="font-w-400 text-success">{{cards.total_available | currency}}</strong></h3>
              </div>
              <div class="chart-insights text-left" style="margin-top:15px;">
              </div>
              <div></div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content relative">
          <div class="mini-tab-root tab-elevation1 tab-rounded"  style="margin: 10px; float:right;position: absolute; right: -10px;top: -35px;">
            <div class="mini-tab-content">
                <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
                  <div class="mini-tab-flex">
                      <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                        <span>
                          Faturas abertas
                        </span>
                        <span class="MuiTouchRipple-root"></span>
                      </button>
                      <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 160)">
                        <span>
                          Faturas fechadas
                        </span>
                        <span class="MuiTouchRipple-root"></span>
                      </button>
                  </div>
                  <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px;`"></span>
                </div>
            </div>
          </div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'open'}" v-if="tabActive == 'open'">
            <div class="card card-tab table-responsive longer no-shadow" style="border-radius: 10px !important;">
              <h4 class="text-left block" style="float: left"></h4>
            <div>
              <div style="clear: both"></div>
              </div>
              <div v-if="isLoading" class="row">
                <div class="col-sm-8">
                  <div class="shimmerBG title-line" style="width: 40%; margin-top:15px; height: 150px; float:left;"></div>
                  <div class="shimmerBG title-line" style="width: 40%; margin-top:15px; height: 150px; float:left; margin-left:10px;"></div>
                </div>
                <div class="col-sm-4">
                  <div class="shimmerBG title-line" style="width: 30%; margin-top:15px; height: 20px;"></div>
                  <div class="shimmerBG title-line" style="width: 20%; margin-top:15px; height: 30px;"></div>
                </div>
                <div style="clear:both;"></div>
                <br>
                <br>
                <br>
              </div>
              <div v-else class="row">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <!--Box-->
                  <div class="inner-box" v-if="this.cards.opened_invoice.length > 0">
                    <div class="cards-wrapper">

                      <!--Carousel-->
                      <div class="cards-carousel">
                        <!-- Slider main container -->
                        <div
                          class="
                            cards-carousel-inner
                            slick-initialized slick-slider slick-dotted
                          "
                        >
                          <!--Carousel Item-->
                          <div>
                            <div>
                              <carousel :perPage="2" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="`<i class='fas fa-angle-right arrow-swipper'></i>`" :navigationPrevLabel="`<i class='fas fa-angle-left arrow-swipper'></i>`">
                                <slide v-for="(card, i) in this.cards.opened_invoice" :key="i" :data-index="i" @slideclick="handleSlideCard">
                                  <div
                                    class="
                                      cards-carousel-item
                                      slick-slide slick-cloned
                                    "
                                    data-slick-index="6"
                                    id=""
                                    aria-hidden="true"
                                    tabindex="-1"
                                  >
                                    <div class="ccard credit-card-shadow ccard-hover" style="height: 195px; width: 300px;">
                                      <div class="shape"></div>
                                      <div class="top">
                                        <img
                                          class="light-image inactive"
                                          :src="`/assets/images/cards/${card.brand}.png`"
                                          alt=""
                                        />
                                        {{ card.name }}
                                        <div class="card-date">
                                          <span>Fecha em</span>
                                          <strong>{{ card.close_date | moment("DD") }} de {{ card.close_date | moment("MMM") }}</strong>
                                          <span>Valor parcial</span>
                                          <strong>{{ card.open_value | currency }}</strong>
                                        </div>
                                      </div>
                                      <div class="card-info-pay">
                                        <span class="badge badge-info">Vence em: {{ card.payment_date | moment("DD") }} de {{ card.payment_date | moment("MMM") }}</span>
                                      </div>
                                      <div class="bottom">
                                        <span style="font-size: 13px;">{{ card.spent | currency }} de {{ card.card_limit | currency }}</span>
                                        <div class="progress progress-card" style="height: 10px !important;" :class="{'progress-bar-danger-alt': card.progress > 85, 'progress-bar-warning-alt': card.progress > 70 && card.progress < 85, 'progress-bar-success-alt': card.progress < 70 }">
                                          <div class="progress-bar" :class="{'progress-bar-danger': card.progress > 85, 'progress-bar-warning': card.progress > 70 && card.progress < 85, 'progress-bar-success': card.progress < 70 }" role="progressbar" :style="`width:${card.progress}%`">
                                            <span class="hide">{{ card.progress }}%</span>
                                          </div>
                                        </div>
                                        <span style="font-size: 13px; margin-bottom: 0px; font-weight: 100;">Limite disponível: <strong>{{ card.available | currency }}</strong></span>
                                      </div>
                                    </div>
                                  </div>
                                </slide>
                              </carousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="this.cards.opened_invoice.length == 0">
                    <img :src="`/assets/images/invoice.svg`" style="opacity: 0.2; margin-top: 5px;" class="nothing-image w-15p"/>
                    <h4 class="nothing-image text-center" style="width: 40%">Nenhuma fatura aberta</h4>
                    <br>
                    <br>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div class="card no-shadow card-tab" style="margin-top:18px; margin-bottom:30px !important; margin-right:15px; border-radius:10px !important;">
                    <div class="card-header">
                      <h4>Valor total</h4>
                    </div>
                    <div class="card-body">
                      <section class="text-center mt-15">
                        <h1 class="reports-expenses text-left font-w-100 ng-binding">{{ cards.opened_value | currency }}</h1>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'closed'}" v-if="tabActive == 'closed'">
            <div class="card card-tab table-responsive longer no-shadow" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <h4 class="text-left block" style="float: left"></h4>
            <div>
              <div style="clear: both"></div>
              </div>
              <div v-if="isLoading" class="row">
                <div class="col-sm-8">
                  <div class="shimmerBG title-line" style="width: 40%; margin-top:15px; height: 150px; float:left;"></div>
                  <div class="shimmerBG title-line" style="width: 40%; margin-top:15px; height: 150px; float:left; margin-left:10px;"></div>
                </div>
                <div class="col-sm-4">
                  <div class="shimmerBG title-line" style="width: 30%; margin-top:15px; height: 20px;"></div>
                  <div class="shimmerBG title-line" style="width: 20%; margin-top:15px; height: 30px;"></div>
                </div>
                <div style="clear:both;"></div>
                <br>
                <br>
                <br>
              </div>
              <div v-else class="row">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <!--Box-->
                  <div class="inner-box" v-if="this.cards.invoice_closed.length > 0">
                    <div class="cards-wrapper">

                      <!--Carousel-->
                      <div class="cards-carousel">
                        <!-- Slider main container -->
                        <div
                          class="
                            cards-carousel-inner
                            slick-initialized slick-slider slick-dotted
                          "
                        >
                          <!--Carousel Item-->
                          <div>
                            <div>
                              <carousel :perPage="2" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="`<i class='fas fa-angle-right arrow-swipper'></i>`" :navigationPrevLabel="`<i class='fas fa-angle-left arrow-swipper'></i>`">
                                <slide v-for="(card, i) in this.cards.invoice_closed" :key="i" :data-index="i" @slideclick="handleSlideCard">
                                  <div
                                    class="
                                      cards-carousel-item
                                      slick-slide slick-cloned
                                    "
                                    data-slick-index="6"
                                    id=""
                                    aria-hidden="true"
                                    tabindex="-1"
                                  >
                                    <div class="ccard credit-card-shadow ccard-hover" style="height: 195px; width: 300px;">
                                      <div class="shape"></div>
                                      <div class="top">
                                        <img
                                          class="light-image inactive"
                                          :src="`/assets/images/cards/${card.brand}.png`"
                                          alt=""
                                        />
                                        {{ card.name }}
                                        <div class="card-date">
                                          <span>Fechou em</span>
                                          <strong>{{ card.cinvo_close_date | moment("DD") }} de {{ card.cinvo_close_date | moment("MMM") }}</strong>
                                          <span>Valor total</span>
                                          <strong>{{ card.close_value | currency }}</strong>
                                        </div>
                                      </div>
                                      <div class="card-info-pay" v-if="!card.payment_due">
                                        <span class="badge badge-info">Vence em: {{ card.cinvo_payment_date | moment("DD") }} de {{ card.cinvo_payment_date | moment("MMM") }}</span>
                                      </div>
                                      <div class="card-info-pay" v-if="card.payment_due">
                                        <span class="label label-warning">Fatura vencida</span>
                                      </div>
                                      <div class="bottom">
                                        <span style="font-size: 13px;">{{ card.spent | currency }} de {{ card.card_limit | currency }}</span>
                                        <div class="progress progress-card" style="height: 10px !important;" :class="{'progress-bar-danger-alt': card.progress > 85, 'progress-bar-warning-alt': card.progress > 70 && card.progress < 85, 'progress-bar-success-alt': card.progress < 70 }">
                                          <div class="progress-bar" :class="{'progress-bar-danger': card.progress > 85, 'progress-bar-warning': card.progress > 70 && card.progress < 85, 'progress-bar-success': card.progress < 70 }" role="progressbar" :style="`width:${card.progress}%;`">
                                            <span class="hide">{{ card.progress }}%</span>
                                          </div>
                                        </div>
                                        <span style="font-size: 13px; margin-bottom: 0px; font-weight: 100;">Limite disponível: <strong>{{ card.available | currency }}</strong></span>
                                      </div>
                                    </div>
                                  </div>
                                </slide>
                              </carousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="this.cards.invoice_closed.length == 0">
                    <img :src="`/assets/images/invoice.svg`" style="opacity: 0.2; margin-top: 5px;" class="nothing-image w-15p"/>
                    <h4 class="nothing-image text-center" style="width: 40%">Nenhuma fatura fechada</h4>
                    <br>
                    <br>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div class="card no-shadow card-tab" style="margin-top:18px; margin-bottom:30px !important; margin-right:15px; border-radius:10px !important;">
                    <div class="card-header">
                      <h4>Valor total</h4>
                    </div>
                    <div class="card-body">
                      <section class="text-center mt-15">
                        <h1 class="reports-expenses text-left font-w-100 ng-binding">{{ cards.closed_value | currency }}</h1>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import CalendarCo from '../components/Calendar.vue';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: 'Home',
  components: {
    CalendarCo,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      cards: [],
      categories: [],
      wallets: {wallets: []},
      yearStart: 2023,
      filters: [
        {
          field: '',
          values: [
          ],
        },
      ],
      fields: [
        { label: 'Contas', value: 'account_id' },
        { label: 'Categoria', value: 'category' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      tabActive: 'open',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      monthStart: 0,
      from: '',
      to: '',
      report: {},
      ToastError: {},
      boxFlow: {
        income: {},
        expense: {},
        total: {},
        group: {
          all: {
            item: [],
          },
          income: {
            item: [],
          },
          expenses: {
            item: [],
          }
        }
      },
      isLoading: true,
    };
  },
  provide: {
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    this.yearStart = Number(moment(new Date()).format('YYYY'));
    this.$forceUpdate();
    console.log('Mês', this.monthStart);
    pace.start();

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.optionsDate.startDate = `${moment(new Date()).format('01/MM/YYYY')}`;
    this.optionsDate.endDate = moment(this.from, "YYYY-MM-DD").endOf('month').format('DD/MM/YYYY');

    AppService.getCategories({}).then(
      (response) => {
        this.categories = response.categories;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );
    this.request();
    this.$root.$on('register.card', this.request);
  },
  methods: {
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
      switch (tab) {
        case 0:
          this.tabActive = 'open';
          break;
        case 1:
          this.tabActive = 'closed';
          break;
        default:
          break;
      }
    },
    handleSlideCard (dataset) {
      console.log(dataset.index);
      console.log(this.cards.cards);
      this.$router.push(`/card/detail/${this.cards.cards[dataset.index].id}`);
    },
    navigateDateTo (month) {
      console.log(month);
      this.from = `${month.year}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.request();
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    request() {
      AppService.getCards({
        from: this.from,
        to: this.to,
        check_sub: true,
      }).then(
        (response) => {
          this.cards = response;
          this.isLoadingCards = false;
          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
      
      AppService.getInfo({
        from: this.from,
        to: this.to,
        only_card: true,
      }).then(
        (response) => {
          this.isLoading = false;
          this.report = response;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
  },
};
</script>

<style scoped lang="scss">
.card-info-pay {
  top: 60px;
  position: absolute;
}
.card-info-pay span {
  font-size: 11px;
}
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 200px;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.right-transaction{
  position: absolute;
  right: 0;
  top: 0;
}
.credit-card-shadow{
  box-shadow:   0px 2px 1px -1px rgba(159, 159, 159, 0.2), 0px 1px 1px 0px rgba(140, 140, 140, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
</style>
